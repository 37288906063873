<template>
    <div class="body">
        <Header />

        <div class="main">
            <section class="page-header parallax page-header-text-light mb-0 bg_img black_cover" :style="`background-image: url(${require(`@/assets/img/bg/contact_1.jpg`)})`">
                <div class="container py-5 my-5">
                    <div class="row pt-5 mt-5">
                        <div class="col-md-12">
                            <ul class="breadcrumb" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100">
                                <li><router-link to="/">Home</router-link></li>
                                <li><a href="#">Contact</a></li>
                                <li class="active">Contact us</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="font-weight-bold display-4">Hello, Welcome!</p>
                            <!-- <p class="lead" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container">
                    <div class="row text-left">
                        <div class="col">
                            <!-- <span class="top-sub-title text-color-primary" data-appear-animation="fadeInUpShorter">LOREM IPSUM DOLOR SIT</span> -->
                            <h2 class="font-weight-bold" data-appear-animation="fadeInUpShorter">Contact Us</h2>
                            <!-- <p class="lead" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">If you have any further questions or queries please do not hesitate to get in touch.</p> -->
                        </div>
                    </div>
                    <div class="row pt-5">
                        <div class="col-lg-4">
                            <img src="@/assets/img/bg/contact_2.jpg" alt="" class="w-100 object_fit_cover sub_img">
                        </div>
                        <div class="col-lg-8" data-appear-animation="fadeInRightShorter">
                            <div class="d-flex justify-content-center align-items-center flex-wrap py-4 border-bottom">
                                <div>
                                    <img src="@/assets/img/contact/1.png" width="50">
                                    <p class="text-center mb-0">접수</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/img/contact/arrow.png" class="px-2" width="40">
                                    <div>
                                        <img src="@/assets/img/contact/2.png" width="50">
                                        <p class="text-center mb-0">접수완료</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/img/contact/arrow.png" class="px-2" width="40">
                                    <div>
                                        <img src="@/assets/img/contact/3.png" width="50">
                                        <p class="text-center mb-0">분류</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/img/contact/arrow.png" class="px-2" width="40">
                                    <div>
                                        <img src="@/assets/img/contact/4.png" width="50">
                                        <p class="text-center mb-0">처리</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/img/contact/arrow.png" class="px-2" width="40">
                                    <div>
                                        <img src="@/assets/img/contact/5.png" width="50">
                                        <p class="text-center mb-0">결과안내</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/img/contact/arrow.png" class="px-2" width="40">
                                    <div>
                                        <img src="@/assets/img/contact/6.png" width="50">
                                        <p class="text-center mb-0">조치</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row py-4 border-bottom">
                                <div class="col-sm-6 col-12">
                                    <div class="mb-4">
                                        <h6>[ 접수 ]</h6>
                                        <p>ㆍ홈페이지, 방문, 우편, 전화, 이메일 등</p>
                                    </div>
                                    <div class="mb-4">
                                        <h6>[ 접수완료 ]</h6>
                                        <p>ㆍ민원접수 확인 후, 민원처리 담당자 지정</p>
                                        <p>ㆍ민원접수자에게 이메일, 문자메시지, 전화 등을 통해 접수 완료 안내</p>
                                    </div>
                                    <div class="mb-4">
                                        <h6>[ 분류 ]</h6>
                                        <p>ㆍ민원 유형 : 가맹점 가입, 수수료, 한도, 정산, 가맹점 정보변경, 전산장애, 기타 등</p>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="mb-4">
                                        <h6>[ 처리 ]</h6>
                                        <p>
                                            ㆍ민원처리 접수일 + 14일 이내 처리 원칙<br>
                                            <span class="ml-2">-</span> 대외기관에서 이관된 민원 제외
                                        </p>
                                        <p>ㆍ민원처리 과정 중 문제 발생 시, 민원 접수자에게 이메일, 문자, 전화 등을 통해 안내</p>
                                    </div>
                                    <div class="mb-4">
                                        <h6>[ 결과안내 ]</h6>
                                        <p>
                                            ㆍ민원 접수자에게 이메일, 문자, 전화 등을 통해 민원 처리 결과 안내<br>
                                            <span class="ml-2">-</span> 대외기관 민원건은 해당 기관으로 결과 회신
                                        </p>
                                    </div>
                                    <div class="">
                                        <h6>[ 조치 ]</h6>
                                        <p>ㆍ민원 유형별 한도/할부제한, 담보 상향 등 조치</p>
                                    </div>
                                </div>
                            </div>
                            <div class="py-4 border-bottom">
                                <p class="h6 mb-2 text-dark"><b>전자금융거래 분쟁처리 책임자</b></p>
                                <div class="row">
                                    <div class="col-sm-6 col-12">
                                        ㆍ전자금융거래 분쟁처리 책임자
                                        <p><span class="ml-2">-</span> 담당자명 : 박상현</p>
                                        <p><span class="ml-2">-</span> 전화번호 : 1533-4178</p>
                                        <p><span class="ml-2">-</span> 이메일 : marcus@wonsungglobal.com</p>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        ㆍ전자금융거래 분쟁처리 담당자
                                        <p><span class="ml-2">-</span> 담당자명 : 조길형</p>
                                        <p><span class="ml-2">-</span> 전화번호 :  1533-4178</p>
                                        <p><span class="ml-2">-</span> 이메일 : wonsungman@wonsungglobal.com</p>

                                    </div>
                                </div>
                            </div>
                            <div class="py-4">
                                <p class="h6 mb-2 text-dark"><b>상담신청</b></p>
                                <div>
                                    <form class="contact-form form-style-1">
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <input type="text" v-model="name" data-msg-required="성함을 입력해주세요." maxlength="100" class="form-control border" name="name" id="name" placeholder="성함" required>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <input type="tel" v-model="tel" data-msg-required="전화번호를 입력해주세요." maxlength="100" class="form-control border" name="tel" id="tel" placeholder="전화번호(핸드폰 번호)" required>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col">
                                                <input type="email" v-model="email" data-msg-required="이메일을 입력해주세요." data-msg-email="이메일 형식에 맞게 입력해주세요." maxlength="100" class="form-control border" name="email" id="email" placeholder="이메일" required>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-sm-3 mb-3 pr-0 flex-grow-1">
                                                <div class="form-control border h-100" style="background-color: #f1f3f7">상담분류</div>
                                            </div>
                                            <div class="form-group col-sm-9">
                                                <ul class="d-flex flex-wrap pl-0 mb-0">
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="가맹점가입"
                                                        class="form-check-input" type="radio" id="radio1">
                                                        <label class="form-check-label" for="radio1">
                                                            가맹점가입
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="가맹점 한도"
                                                        class="form-check-input" type="radio" id="radio2">
                                                        <label class="form-check-label" for="radio2">
                                                            가맹점 한도
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="가맹점 입금(정산)"
                                                        class="form-check-input" type="radio" id="radio3">
                                                        <label class="form-check-label" for="radio3">
                                                            가맹점 입금(정산)
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="전산장애"
                                                        class="form-check-input" type="radio" id="radio4">
                                                        <label class="form-check-label" for="radio4">
                                                            전산장애
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="수수료"
                                                        class="form-check-input" type="radio" id="radio5">
                                                        <label class="form-check-label" for="radio5">
                                                            수수료
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="가맹점 일반"
                                                        class="form-check-input" type="radio" id="radio6">
                                                        <label class="form-check-label" for="radio6">
                                                            가맹점 일반
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="가맹점 정보변경"
                                                        class="form-check-input" type="radio" id="radio7">
                                                        <label class="form-check-label" for="radio7">
                                                            가맹점 정보변경
                                                        </label>
                                                    </li>
                                                    <li class="form-check px-4">
                                                        <input
                                                        v-model="classify"
                                                        value="기타"
                                                        class="form-check-input" type="radio" id="radio8">
                                                        <label class="form-check-label" for="radio8">
                                                            기타
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group box_l col-sm-3 mb-0">
                                                <div class="form-control border" style="background-color: #f1f3f7">제목</div>
                                            </div>
                                            <div class="form-group col box_r">
                                                <input type="text" v-model="title" data-msg-required="제목을 입력해주세요." data-msg-title="이메일 형식에 맞게 입력해주세요." maxlength="100" class="form-control border" name="title" id="title" placeholder="" required>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-sm-3 mb-0 flex-grow-1 box_l">
                                                <div class="form-control border h-100" style="background-color: #f1f3f7">내용</div>
                                            </div>
                                            <div class="form-group col mb-0 box_r">
                                                <textarea maxlength="5000" data-msg-required="문의내용을 입력해주세요." rows="8" class="form-control border" name="message" id="message" placeholder="" v-model="message" required></textarea>
                                            </div>
                                        </div>
                                        
                                        <hr>
                                        <p class="h6 mb-2 text-dark"><b>개인정보 보호를 위한 이용자 동의사항</b></p>
                                        <div class="mb-5">
                                            <p>처리목적 : 고객 문의에 대한 답변 및 현황 관리</p>
                                            <p>
                                                처리항목 : 성암(회사명), 연락처, 이메일
                                            </p>
                                            <p class="mb-2">
                                                보유기간 : 개인정보 수집 및 이용에 관한 동의 후 36개월 간 보유
                                            </p>
                                            <p>※ 귀하는 개인정보 수집 및 이용에 동의하지 않으실 수 있습니다. 그러나 동의하지 않을 경우 고객 상담이 원활하게 이루워지지 않을 수 있습니다.</p>

                                            
                                            <div class="form-check mt-4">
                                                <input
                                                v-model="terms"
                                                class="form-check-input" type="checkbox" id="checkbox1" required>
                                                <label class="form-check-label" for="checkbox1">
                                                    (필수)개인정보 수집 및 이용에 동의합니다.
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-row mt-2">
                                            <div class="col">
                                                <input type="submit" value="상담 접수" class="btn btn-primary btn-rounded btn-4 font-weight-semibold h4" data-loading-text="Loading...">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, Footer
  },
  data(){
    return{
        name: '',
        tel: '',
        company: '',
        email: '',
        message: '',
        title: '',
        classify: '',
        terms: false,
    }
  }
}
</script>
<style scoped lang="scss">
    .sub_img{
        filter: saturate(0.3) opacity(0.7);
    }
    p{
        margin-bottom: 0px;
        line-height: 1.4;
    }
    .h-100{
        height: 100% !important;
    }

    @media screen and (max-width: 575px) {
        .box_l{
            .form-control{
                border-bottom: 0 !important;
            }
        }
    }
    @media screen and (min-width: 575.1px) {
        .box_l{
            padding-right: 0;
            
            .form-control{
                border-right: 0 !important;
            }
        }
        .box_r{
            padding-left: 0;
        }
    }
</style>