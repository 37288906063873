<template>
    <div class="body">
        <Header />

        <div class="main">
            <CompanyHeader :title="title" />

            <section class="section">
                <div class="container">
                    <div class="row pt-5">
                        <div class="col-lg-4">
                            <div class="row">                            
                                <h2 class="font-weight-bold mb-5 ml-2" data-appear-animation="fadeInUpShorter">(주)원성글로벌</h2>
                                <div class="col-12 col-md-4 col-lg-12 mb-4 " data-appear-animation="fadeInLeftShorter">
                                    <div class="icon-box icon-box-style-1">
                                        <div class="icon-box-icon">
                                            <i class="lnr lnr-apartment text-color-primary"></i>
                                        </div>
                                        <div class="icon-box-info mt-1">
                                            <div class="icon-box-info-title">
                                                <h3 class="font-weight-bold text-4 mb-0">Address</h3>
                                            </div>
                                            <div class="line-height-3">
                                                <p class="mb-2 mt-2"><strong>본점</strong><br>(18625) 경기도 화성시 향남읍 발안로 679-14</p>
                                                <p><strong>기업부설연구소</strong><br>(06154) 서울특별시 강남구 봉은사로 460, 10층 (삼성동, 금척타워)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-12 mb-4 " data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">
                                    <div class="icon-box icon-box-style-1">
                                        <div class="icon-box-icon icon-box-icon-no-top">
                                            <i class="lnr lnr-envelope text-color-primary"></i>
                                        </div>
                                        <div class="icon-box-info mt-1">
                                            <div class="icon-box-info-title">
                                                <h3 class="font-weight-bold text-4 mb-0">Email Address</h3>
                                            </div>
                                            <p><a href="mailto:help@wonsungglobal.com">help@wonsungglobal.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-12" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">
                                    <div class="icon-box icon-box-style-1">
                                        <div class="icon-box-icon">
                                            <i class="lnr lnr-phone-handset text-color-primary"></i>
                                        </div>
                                        <div class="icon-box-info mt-1">
                                            <div class="icon-box-info-title">
                                                <h3 class="font-weight-bold text-4 mb-0">Phone Number</h3>
                                            </div>
                                            <p><a href="tel:15334178">1533-4178</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 pt-5 pt-lg-0" data-appear-animation="fadeInRightShorter">
                            <div class="map">
                                <img src="@/assets/img/company/map.png" alt="주소 : 서울특별시 강남구 봉은사로 460, 10층 (삼성동, 금척타워)" width="100%">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

        <Footer />

    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from './CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, CompanyHeader, Footer
  },
  data(){
    return{
        title: '오시는 길'
    }
  }
}
</script>


<style scoped>
    .map{
        height: 500px;
    }
</style>